@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html,
body {
    height: 100%;
}

#root {
    min-height: 100%;
}

body {
    min-width: 300px;
    max-width: 100vw;
    overflow-x: auto;
}

.focused {
    background-color: aqua;
}

.mention {
    color: #0052CC;
    font-weight: 400;
}

#list-page-header {
    font-size: 1.3rem;
}

blockquote {
    background-color: #F4F4F4;
    padding: 10px 15px;
    border-radius: 5px;
}

/* .InovuaReactDataGrid__cell__content {
    overflow: visible !important;
} */


trix-editor {
    background-color: white;
}

.trix-button-group--file-tools,
.trix-button--icon-attach,
.trix-button-group--file-tools,
span[data-trix-button-group=file-tools] {
    display: none !important;
}

div[data-props-id=actions] {
    background: white !important;
}

.pvtFilteredAttribute {
    font-weight: bold !important;
    color: rgb(23, 43, 77) !important;
}

#contentWrapper>div {
    overflow-x: auto !important;
}

a:hover {
    text-decoration: none !important;
}

/* #atlassian-navigation-notification-count>div>span {
    background-color: blue !important;
} */


.b-highlighting .b-sch-event {
    opacity: 0.3 !important;
}

.b-highlighting .b-match {
    opacity: 1 !important;
    box-shadow: 0 0 8px gray;
}

.InovuaReactDataGrid {
    z-index: 204 !important;
}

.InovuaReactDataGrid__virtualScroller {
    overflow: visible !important;
}

.quill {
    background-color: white;
}

.ql-editor {
    min-height: 60px;
}

.ql-align-justify {
    text-align: justify
}

.wysiwyg-output {
    white-space: pre;
}

.InovuaReactDataGrid__cell__content {
    /* overflow: visible !important;
    white-space: initial; */
}